<template>
  <div class="w-full md:w-11/12 lg:w-10/12">
    <div
      class="flex items-center text-left font-proximaMedium font-bold text-2xl"
    >
      <img
        rel="prefetch"
        class="mr-2 rounded-full w-24 h-24 object-cover"
        :src="pet.profile_picture"
        :alt="pet.callName"
      />

      <h1 class="pl-4 text-3xl font-proximaMedium font-bold">
        {{ this.pet.callName }}
      </h1>
    </div>
    <div class="w-1000 md:w-full">
      <FormError :errors="errors.save" />
      <FormError :errors="errors.activityTime" />
      <FormError :errors="errors.activity" />
      <FormError :errors="errors.notes" />
      <div
        class="py-6 px-4 mt-4 bg-primary rounded-tl-md rounded-tr-3xl font-proximaMedium text-white"
      >
        <ul class="flex">
          <li class="w-2/12">{{ $t("global_time") }}</li>
          <li class="w-4/12">{{ $t("global_activity") }}</li>
          <li class="w-6/12">{{ $t("global_notes") }}</li>
        </ul>
      </div>
      <div v-for="(routine, i) in routinesComp" :key="i">
        <RoutineTableEach
          :routine="routine"
          @deleteRout="deleteRoutine"
          @editRout="editRoutine"
          ref="child"
        />
      </div>
    </div>
    <div class="w-full mt-4 px-8 flex justify-between">
      <!--  @click.native="bulkShareModals = !bulkShareModals" -->
      <AddIcon
        v-if="!activityAdded"
        class="w-14 h-14 cursor-pointer"
        @click.native="submitAddIcon"
      />
      <div v-else></div>
      <div>
        <!-- FIXME: the individual care routine still the active safe. -->
        <SubmitButton
          v-if="activityAdded"
          :title="$t('account_save_button')"
          background="bg-primary"
          paddingX="px-8"
          marginT="mt-2"
          width="w-max"
          @click.native="submitEditRoutine"
        />
        <SubmitButton
          v-else
          :title="$t('account_save_button')"
          class="cursor-not-allowed"
          background="bg-lightGrey"
          paddingX="px-8"
          marginT="mt-2"
          width="w-max"
          @click.native="submitEditRoutine"
        />
      </div>
    </div>

    <ModalsConfirm :show="bulkShareModals" @close="bulkShareModals = false">
      <h1 slot="actionQuestion" class="mt-4 text-2xl">
        Select Time Sensitivity
      </h1>
      <div
        slot="actionText"
        class="flex flex-col items-center justify-center text-sm font-proximaLight"
      >
        <span class="my-2"
          >Some activities are time sensitive and trigger reminders.<br />Please
          click on the the time sensitivity.
        </span>
        <div class="mt-2 w-full md:w-10/12 h-32 font-proximaMedium">
          <div class="flex">
            <div class="mr-1 bg-red w-5 h-5 rounded-full"></div>
            <InputRadioEachReverse
              title="must be done within 1 hour of the specified time"
              textCl="text-red"
              :id="1"
              :checked="false"
              @inputVal="onChangeTimeSensitivity"
            />
          </div>
          <div class="flex my-2">
            <div class="mr-1 bg-orange w-5 h-5 rounded-full"></div>
            <InputRadioEachReverse
              title="should be done within 3 hours of the specified time"
              textCl="text-orange"
              :id="2"
              :checked="false"
              @inputVal="onChangeTimeSensitivity"
            />
          </div>
          <div class="flex">
            <div class="mr-1 bg-primary w-5 h-5 rounded-full"></div>
            <InputRadioEachReverse
              title="not time sensitive, the time is a guideline. "
              textCl="text-primary"
              :id="3"
              :checked="false"
              @inputVal="onChangeTimeSensitivity"
            />
          </div>
        </div>
      </div>
      <BorderedButton
        slot="leftBtn"
        class="md:mr-1"
        :title="$t('global_cancel')"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="bulkShareModals = false"
      />
      <SubmitButton
        v-if="confirmTimeSensibilitySelected"
        slot="rightBtn"
        class="md:ml-1"
        :title="$t('global_confirm')"
        background="bg-success"
        paddingX="px-8"
        width="w-full"
        @click.native="confirmTimeSensibility"
      />
      <SubmitButton
        v-else
        slot="rightBtn"
        class="md:ml-1 cursor-not-allowed"
        :title="$t('global_confirm')"
        background="bg-lightGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="confirmTimeSensibility"
      />
    </ModalsConfirm>
  </div>
</template>

<script>
import RoutineTableEach from "./RoutineTableEach.vue";
import AddIcon from "../../icons/AddIcon.vue";
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import InputRadioEachReverse from "../../forms/InputRadioEachReverse.vue";
import FormError from "../../warning/FormError.vue";

import {
  getCareRoutineOfPet,
  deleteRoutine,
  updateRoutine,
  createRoutine,
} from "../../../services/careRoutineService";

export default {
  props: { pet: {} },
  components: {
    RoutineTableEach,
    AddIcon,
    SubmitButton,
    ModalsConfirm,
    BorderedButton,
    InputRadioEachReverse,
    FormError,
  },
  data() {
    return {
      bulkShareModals: false,
      checkAll: false,
      routines: [],
      timeSensibility: 0,
      activityAdded: false,
      confirmTimeSensibilitySelected: false,
      errors: {},
    };
  },
  computed: {
    routinesComp() {
      return this.$store.state.routinesState;
    },
  },
  async created() {
    const acts = await getCareRoutineOfPet(this.pet.petID);

    this.$store.commit("routinesComp", acts.data);
    //console.log(this.routines);
  },
  methods: {
    async deleteRoutine(id) {
      console.log(id);
      if (id != 0) {
        const routines = this.$store.state.routinesState.filter(
          (a) => a.id !== id
        );
        await deleteRoutine(id);
        this.$store.commit("routinesComp", []);
        console.log(routines.length);
        this.$store.commit("routinesComp", routines);
      }
    },

    editRoutine(routine) {
      const routines = [...this.$store.state.routinesState];
      const index = routines.indexOf(routine);
      routines[index] = { ...routine };
      this.$store.commit("routinesComp", routines);
    },

    onChangeTimeSensitivity(i) {
      console.log(i);
      this.timeSensibility = i;
      this.confirmTimeSensibilitySelected = true;
    },

    async confirmTimeSensibility() {
      if (this.timeSensibility != 0) {
        const data = {
          id: 0,
          userEmailAddress: localStorage.getItem("email"),
          pets: [this.pet.petID],
          activity: "",
          activity_time: "",
          time_sensitivity: this.timeSensibility,
          notes: "",
          done: false,
          comment: "",
        };

        const routs = [...this.$store.state.routinesState, data];
        this.$store.commit("routinesComp", routs);
        this.activityAdded = true;
      }
      this.bulkShareModals = false;
    },

    submitAddIcon() {
      this.bulkShareModals = true;
      this.timeSensibility = 0;
      /* this.routines.forEach(async (item) => {
        item["time_sensitivity"] = 0;
      });*/
    },

    validate(data) {
      const errors = {};

      if (data.activity_time === "")
        errors.activityTime = "Time may not be blank.";
      // "Time has wrong format. Use one of these formats instead: hh:mm[:ss[.uuuuuu]].";
      if (data.activity === "") errors.activity = "Activity may not be blank.";
      if (data.notes === "") errors.notes = "Notes may not be blank.";

      return Object.keys(errors).length === 0 ? null : errors;
    },

    submitEditRoutine() {
      this.activityAdded = true;
      console.log("this.routines--- : ", this.$store.state.routinesState);
      try {
        this.$store.state.routinesState.map(async (r, index) => {
          const errors = this.validate(r);
          this.errors = errors || {};
          console.log(this.errors);
          if (errors) return;

          if (r.id !== 0) {
            await updateRoutine(r);
            this.activityAdded = false;
          } else {
            console.log("*************", r);
            const { data: rout } = await createRoutine(r);
            r.id = rout.id;
            console.log(r);
          }
          this.activityAdded = false;
          this.$refs.child[index].closeeditmode();
        });
      } catch (error) {
        if (error && error.response) {
          console.log(error.response);
        }
      }
    },
  },
};
</script>

<style>
</style>
