<template>
  <div>
    <ul class="flex font-proximaLight border-r border-l border-lightGrey20">
      <li
        class="border-r border-b border-lightGrey py-4 w-2/12 flex items-center justify-evenly"
      >
        <span v-if="!editActivityTime">{{ activityTime }}</span>
        <date-picker
          v-else
          class="px-2"
          v-model="activityTime"
          type="time"
          format="HH:mm"
          value-type="format"
          default-value="activityTime"
          @change="onChangeTime()"
        ></date-picker>
        <img
          rel="prefetch"
          src="@/assets/images/edit-icon.png"
          alt="edit-icon"
          @click="editActivityTime = !editActivityTime"
          :class="{ 'pr-2': editActivityTime }"
          class="cursor-pointer"
        />
      </li>
      <li
        class="border-r border-b border-lightGrey p-4 w-4/12 flex items-center justify-between"
      >
        <div class="w-full flex items-center">
          <div
            class="w-max rounded-full p-2 mr-2"
            :class="timeSensibilityClass"
          ></div>
          <span :class="timeSensibilityTextClass" v-if="!editActivityText">{{
            activityText
          }}</span>
          <input
            v-else
            type="text"
            v-model="activityText"
            @change="onChangeActivity()"
            class="w-full px-2"
            @keyup.enter="editActivityText = false"
          />
        </div>
        <img
          rel="prefetch"
          src="@/assets/images/edit-icon.png"
          alt="edit-icon"
          class="ml-2 cursor-pointer"
          @click="editActivityText = !editActivityText"
        />
      </li>
      <li
        class="border-b border-lightGrey py-4 w-6/12 flex items-center justify-between"
      >
        <p v-if="!editNotes" class="w-9/12 pl-2 text-left text-sm">
          {{ routineNotes }}
        </p>
        <textarea
          class="w-9/12 mx-2 p-1 text-sm"
          v-else
          v-model="routineNotes"
          @change="onChangeNote()"
        ></textarea>
        <div class="w-3/12 flex justify-evenly items-center">
          <img
            rel="prefetch"
            src="@/assets/images/edit-icon.png"
            alt="edit-icon"
            @click="editNotes = !editNotes"
            class="cursor-pointer"
          />
          <TrashIcon
            v-if="routine.id != 0"
            width="19"
            height="19"
            iconColor="#fff"
            bgColor="#0AC0CB"
            class="cursor-pointer"
            @click.native="deleteActModals = !deleteActModals"
          />
        </div>
      </li>
    </ul>

    <!-- delete activity modals -->
    <ModalsConfirm :show="deleteActModals" @close="deleteActModals = false">
      <h1 slot="actionQuestion" class="mt-4 text-2xl">Delete activity?</h1>
      <div
        slot="actionText"
        class="my-2 flex items-center justify-center text-sm font-proximaLight"
      >
        <span class="text-red pr-1">{{ $t("global_attention") }} </span>
        <span>{{ $t("routine_table_remove_msg") }}</span>
      </div>
      <BorderedButton
        slot="leftBtn"
        class="md:mr-1"
        :title="$t('global_cancel')"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="deleteActModals = false"
      />
      <SubmitButton
        slot="rightBtn"
        class="md:ml-1"
        :title="$t('global_confirm')"
        background="bg-red"
        paddingX="px-8"
        width="w-full"
        @click.native="deleteRoutine(routine.id)"
      />
    </ModalsConfirm>
    <!-- delete activity modals end -->
  </div>
</template>

<script>
import TrashIcon from "../../icons/TrashIcon.vue";
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import InputRadioEachReverse from "../../forms/InputRadioEachReverse.vue";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  props: ["routine"],
  components: {
    TrashIcon,
    ModalsConfirm,
    SubmitButton,
    BorderedButton,
    DatePicker,
    InputRadioEachReverse,
  },
  data() {
    return {
      deleteActModals: false,
      editNotes: false,
      editActivityText: false,
      editActivityTime: false,
      time: null,
      activityTime: this.routine.activity_time,
      routineNotes: this.routine.notes,
      activityText: this.routine.activity,
      timeSensibility: this.routine.time_sensitivity,
      timeSensibilityClass:
        this.routine.time_sensitivity.id == 1 ||
        this.routine.time_sensitivity == 1
          ? "bg-red"
          : this.routine.time_sensitivity.id == 2 ||
            this.routine.time_sensitivity == 2
          ? "bg-orange"
          : "bg-primary",
      timeSensibilityTextClass:
        this.routine.time_sensitivity.id == 1 ||
        this.routine.time_sensitivity == 1
          ? "text-red"
          : this.routine.time_sensitivity.id == 2 ||
            this.routine.time_sensitivity == 2
          ? "text-orange"
          : "text-primary",
    };
  },
  methods: {
    async deleteRoutine(id) {
      this.$emit("deleteRout", id);

      this.deleteActModals = false;
    },

    onChangeActivity() {
      this.routine.activity = this.activityText;
      this.$emit("editRout", this.routine);
    },

    onChangeTimeSensitivity(i) {
      console.log(i);
      this.timeSensibility = i;
    },

    onChangeTime() {
      this.routine.activity_time = this.activityTime;
      this.$emit("editRout", this.routine);
    },

    onChangeNote() {
      this.routine.notes = this.routineNotes;
      this.$emit("editRout", this.routine);
    },

    closeeditmode() {
      this.editNotes = false;
      this.editActivityText = false;
      this.editActivityTime = false;
    },
  },
};
</script>

<style scoped>
textarea {
  resize: none;
}
</style>