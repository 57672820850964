<template>
  <div class="pb-12">
    <div class="flex justify-start items-end">
      <div class="md:w-6/12"></div>
      <div class="md:w-5/12">
        <HeaderGlobal
          :titlemenu1="$t('account_dropdown_menu_pet_records')"
          :to1="'/petoverview/new'"
          :titlemenu2="this.titlemenu2"
          :to2="'/petrecord'"
          :titlemenu3="$t('pet_dropdown_menu_edit_care_routine')"
          class="pl-4"
        />
      </div>
    </div>
    <div class="mt-12 flex justify-center items-center">
      <RoutineTable :pet="this.pet" />
    </div>
  </div>
</template>

<script>
import HeaderGlobal from "../../header/HeaderGlobal.vue";
import RoutineTable from "./RoutineTable.vue";
import { nameOfPet } from "../../../methods/petMethods";

export default {
  components: {
    HeaderGlobal,
    RoutineTable,
  },
  mounted() {
    window.onpopstate = (event) => {
      this.$router.push({ path: "/petoverview/new" });
      //console.log(event,"aaaaaaaaaaaaaaaaa",event.srcElement.location.pathname);
      /*  this.$router.push({
        path: "/register"
      }); */
    };
  },
  data() {
    return {
      pet: {},
      titlemenu2: "",
      //title2: this.petName + " Record: " + this.$route.params.data.callName,
    };
  },
  async created() {
    var retrievedObject = localStorage.getItem("pet");
    this.pet = JSON.parse(retrievedObject);

    this.titlemenu2 =
      nameOfPet(this.pet.species) + " Record: " + this.pet.callName;
  },

  methods: {},
};
</script>

<style></style>
