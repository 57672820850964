import http from "./httpService";
import axios from "axios";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl + "careRoutine/";

export function getCareRoutineOfPet(id) {
  return http.get(apiEndpoint + "careRoutineActivities/" + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function getCareRoutineByUser(id) {
  return http.get(apiEndpoint + "careRoutineActivitiesByUser/" + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function getEmojiMood() {
  return http.get(apiEndpoint + "EmojiMood/");
}

export function deleteRoutine(id) {
  return http.delete(apiEndpoint + "activity/" + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function updateRoutine(data) {
  const req = {
    id: data.id,
    userEmailAddress: data.userEmailAddress,
    pets: data.pets,
    activity: data.activity,
    activity_time: data.activity_time,
    time_sensitivity: data.time_sensitivity,
    notes: data.notes,
    done: data.done,
    //time_done: data.time_done,
    comment: data.comment,
  };
  console.log(req);
  return http.put(apiEndpoint + "activity/" + data.id, req, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function careRoutineDone(data) {
  const req = {
    time_done: data.time_done,
    comment: data.comment,
  };

  return http.post(apiEndpoint + "checkAsDone/" + data.id, req, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function createRoutine(data) {
  const req = {
    userEmailAddress: data.userEmailAddress,
    pets: data.pets,
    activity: data.activity,
    activity_time: data.activity_time,
    time_sensitivity: data.time_sensitivity,
    notes: data.notes,
    done: data.done,
    //time_done: data.time_done,
    comment: data.comment,
  };

  return http.post(apiEndpoint + "activity/", req, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}
